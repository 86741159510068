import { useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { AnimatedInfiniteSlantedCards } from "@with-nx/simple-ui/organisms";
import { Box } from "simple-effing-primitive-layout";

export const FormsCTA = () => {
  const mobile = useMobile();
  const iconSize = mobile ? 46 : 88;
  const iconOffset = (iconSize / 2 - 2) * -1;

  const images = [
    ...[...Array(45)].map((_, i) => `/assets/cards/${i + 1}.webp`),
  ];

  return (
    <Box
      parse={`d:flex a:center j:center mh:${
        mobile ? 450 : 700
      } p:relative oa:hidden`}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          transform: "translate(-6%) skew(5deg, 8deg)",
          zIndex: 1,
        }}
        id="animated-cards"
      >
        <AnimatedInfiniteSlantedCards
          images={images}
          size={9}
          rows={5}
          duration={60}
        />
      </Box>

      <Box
        style={{
          width: "100%",
          height: "120%",
          position: "absolute",
          top: 0,
          zIndex: 2,
          background:
            "linear-gradient(217.84deg, var(--background), rgba(19, 23, 30, 0.749499) 52%, var(--background) 93%)",
        }}
      />

      <Box
        className="container"
        parse={`d:flex fd:column j:center a:center p:relative h:100%`}
        style={{
          zIndex: 3,
        }}
      >
        <Box parse="d:flex a:center j:center">
          <Box parse={`d:flex ml:-${iconSize}`}>
            <img
              src="/assets/icons/highlight.svg"
              loading="lazy"
              style={{
                position: "relative",
                top: iconOffset,
                right: iconOffset,
              }}
              width={iconSize}
              height={iconSize}
            />
            <Rule
              parse={`!${
                mobile ? "hl" : "dm"
              } d:block mb:12 ta:center p:relative`}
              weight="700"
              tag="span"
            >
              Bring Your Show to Life
            </Rule>
          </Box>
        </Box>

        <Rule
          parse={`!${mobile ? "ps" : "_p"} d:block mb:20 ta:center c:?font3`}
          tag="h4"
        >
          Discover everything you need for your productions!
          <br />
          Download our catalog and order form now.
        </Rule>

        <Box
          css={`d:flex j:center a:center fd:${mobile ? "column" : "row"}`}
          style={{
            rowGap: "12px",
          }}
        >
          <Box parse={`mr:${mobile ? "0" : "12"}`}>
            <DesignedButton
              icon="book_text"
              label="Download Catalog"
              theme="white"
              href="/legal/documents/catalog"
            />
          </Box>
          <Box>
            <DesignedButton
              icon="shopping_bag"
              label="Download Order Form"
              theme="tetriary-white"
              href="/legal/documents/order-form"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
